import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IOrderExtended } from "./types";
import { GetOrderStatus } from "@portal/components/pages/orders/helper";
import { IOrder } from "@portal/types/api/IOrder";

const initialState = {
  orders: [] as IOrderExtended[],
  loading: true,
};

export const orders = createSlice({
  name: "orders",
  initialState,
  reducers: {
    loadingComplete: state => {
      state.loading = false;
    },
    orderUpdate: (state, action: PayloadAction<IOrder>) => {
      state.loading = false;
      state.orders = [
        ...state.orders.filter(o => o.orderNumber !== action.payload.orderNumber),
        {
          ...action.payload,
          orderType:
            action.payload.isin === "XX0000000002"
              ? ""
              : action.payload.orderQuantity && action.payload.orderQuantity < 0
              ? "Selg"
              : "Kjøp",
          statusText: GetOrderStatus(action.payload),
        },
      ];
    },
    updateLastPrice: (state, action: PayloadAction<{ ticker: string; lastPrice: number }>) => {
      state.orders = state.orders.map(o => {
        if (o.tickerCode === action.payload.ticker) {
          return {
            ...o,
            lastPrice: action.payload.lastPrice,
            lastPriceVsLimit: o.orderLimit ? (Math.abs(action.payload.lastPrice - o.orderLimit) * 100) / o.orderLimit : undefined,
          };
        }
        return o;
      });
    },
    updateYesterdayClose: (state, action: PayloadAction<{ ticker: string; yesterdayClose: number }>) => {
      state.orders = state.orders.map(o => {
        if (o.tickerCode === action.payload.ticker) {
          return {
            ...o,
            yesterdayClose: action.payload.yesterdayClose,
            yesterdayCloseVsLimit: o.orderLimit
              ? (Math.abs(action.payload.yesterdayClose - o.orderLimit) * 100) / o.orderLimit
              : undefined,
          };
        }
        return o;
      });
    },
    clearOrders: state => {
      state.orders = [];
    },
  },
});
export const { orderUpdate, clearOrders, loadingComplete, updateLastPrice, updateYesterdayClose } = orders.actions;
export default orders.reducer;
